import gql from 'graphql-tag';

export default gql`
query getAllTemplateForms {
  forms(where: {is_template: {_eq: true}}) {
    form_id
    name
    description
    form_data
    preview_image_url
  }
}
`;
