<template>
  <div>
    <b-modal
        id="modal-component-form"
        size="lg"
        no-close-on-backdrop
        header-class="d-block"
        centered
    >
      <template #modal-header>
        <h3>{{ $t('modals.createFormItem.choose_form_template') }}</h3>
      </template>
      <template #default>
        <b-row>
          <b-col cols="12" sm="3" class="border-right-light-grey">
            <ul class="form_templates pl-2 mt-5 pb-5">
              <li class="nav-item">
                <a
                    href="#"
                    :class="{ active: (selectedFormIdComp === null) }"
                    class="nav-link"
                    @click="loadEmptyContent()"
                >
                  {{ $t('modals.createFormItem.form.emptyForm') }}
                </a>
              </li>
              <li v-for="form in allTemplateForms" :key="form.form_id" class="nav-item">
                <a
                  href="#"
                  :class="{ active: (selectedFormIdComp === form.form_id) }"
                  class="nav-link"
                  @click="loadFormContent(form.form_id)">{{ form.name }}</a>
              </li>
            </ul>
          </b-col>
          <b-col cols="12" sm="9">
            <div v-if="selectedFormIdComp === null" class="m-5 pb-5">
              <h4>{{ $t('modals.createFormItem.form.emptyFormTitle') }}</h4>
              <p>{{ $t('modals.createFormItem.form.emptyFormDesc') }}</p>
              <b-button
                  class="mr-0 ml-0 green-button"
                  @click="showFormNameModal"
              >
                <b-icon
                    icon="plus-circle-fill"
                    class="mr-2"
                    aria-hidden="true"
                    scale="1"
                ></b-icon>
                {{ $t('webmag.select') }}
              </b-button>
            </div>
            <modal-content-show-form-preview
              v-else
              :name="selectedTemplateNameComp"
              :description="selectedTemplateDescComp"
              :imageUrl="selectedTemplateImage"
              @select-template="templateSelected"
            />
          </b-col>
        </b-row>
      </template>

      <template #modal-footer>
        <b-button
            @click="closeAllModals"
            block
            class="p-3"
        >
          {{ $t('buttons.cancel') }}
        </b-button>
      </template>
    </b-modal>

    <b-modal
        id="modal-add-new-form-item"
        ref="modal-add-new-form-item"
        footer-class="flex-nowrap"
        no-close-on-backdrop
        no-stacking
        centered
    >
      <template #modal-header>
        <h3>{{ $t('modals.createFormItem.title') }}</h3>
      </template>

      <template #default>
        <modal-choose-name-and-description
            :namePlaceholder="$t('modals.createFormItem.namePlaceholder')"
            :descriptionPlaceholder="$t('modals.createFormItem.descriptionPlaceholder')"
            @update-fields="updateFields"
            @enter-pressed="createFormItem"
        ></modal-choose-name-and-description>
      </template>

      <template #modal-footer>
        <b-button
            @click="closeAllModals"
            block
            class="p-3"
        >
          {{ $t('buttons.cancel') }}
        </b-button>
        <b-button
            :disabled="!isFormItemNameSet"
            variant="primary"
            class="p-3"
            block
            @click="createFormItem"
        >
          {{ $t('modals.createFormItem.validate') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import GetAllTemplateForms from '@/graphQlQueries/queries/forms/getAllTemplateForms';

export default {
  name: 'ModalAddNewFormItem',
  components: {
    ModalContentShowFormPreview: () => import('@/components/modals/modalContent/ModalContentShowFormPreview.vue'),
    ModalChooseNameAndDescription: () => import('@/components/modals/ModalChooseNameAndDescription.vue'),
  },
  data: () => ({
    selectedFormId: null,
    selectedTemplateName: null,
    selectedTemplateDesc: null,
    previewKey: 1,
    itemName: null,
    formItemName: null,
    formItemDescription: null,
    allTemplateForms: [],
  }),
  computed: {
    isFormItemNameSet() {
      return !!this.formItemName;
    },
    selectedFormIdComp() {
      return this.selectedFormId;
    },
    selectedTemplateNameComp() {
      return this.selectedTemplateName;
    },
    selectedTemplateDescComp() {
      return this.selectedTemplateDesc;
    },
  },
  apollo: {
    allTemplateForms: {
      query: GetAllTemplateForms,
      update(data) {
        const { forms } = data;
        return forms;
      },
    },
  },
  methods: {
    loadEmptyContent() {
      this.selectedFormId = null;
      this.selectedTemplateName = null;
      this.selectedTemplateDesc = null;
      this.selectedTemplateImage = null;
      this.previewKey++;
    },
    loadFormContent(formId) {
      this.selectedFormId = formId;
      const selectedTemplate = this.allTemplateForms.find((form) => form.form_id === formId);
      this.selectedTemplateName = selectedTemplate ? selectedTemplate.name : null;
      this.selectedTemplateImage = selectedTemplate ? selectedTemplate.preview_image_url : null;
      this.selectedTemplateDesc = selectedTemplate ? selectedTemplate.description : null;
    },
    showFormNameModal() {
      this.$root.$emit('bv::hide::modal', 'modal-component-form');
      this.$root.$emit('bv::show::modal', 'modal-add-new-form-item');
    },
    closeAllModals() {
      this.selectedFormId = null;
      this.selectedTemplateName = null;
      this.selectedTemplateDesc = null;
      this.selectedTemplateImage = null;
      this.$root.$emit('bv::hide::modal', 'modal-component-form');
      this.$root.$emit('bv::hide::modal', 'modal-add-new-form-item');
    },
    templateSelected() {
      this.showFormNameModal();
    },
    confirmOkModalByEnterKey() {
      this.handleOk();
    },
    updateFields(key, value) {
      if (key === 'name') {
        this.formItemName = value.trim();
      } else {
        this.formItemDescription = value.trim();
      }
    },
    async createFormItem() {
      if (this.isFormItemNameSet && this.selectedFormId) {
        const selectedTemplateForm = this.allTemplateForms.find((form) => form.form_id === this.selectedFormId);
        const templateFormData = selectedTemplateForm ? selectedTemplateForm.form_data : null;
        this.$emit('duplicate-form-item', {
          name: this.formItemName,
          desc: this.formItemDescription,
          formId: this.selectedFormId,
          templateFormData,
        });
        this.$refs['modal-add-new-form-item'].hide();
      } else if (this.isFormItemNameSet) {
        this.$emit('create-form-item', { name: this.formItemName, desc: this.formItemDescription });
        this.$refs['modal-add-new-form-item'].hide();
      }
      this.selectedFormId = null;
      this.selectedTemplateName = null;
      this.selectedTemplateDesc = null;
      this.selectedTemplateImage = null;
    },
  },
};
</script>

<style lang="scss">
ul {
  list-style: none;
  font-size: 15px;
}

li a {
  font-size: 15px;
  font-weight: 600;
  background-color: transparent;

  &.active {
    color: #53dc86;
  }
}
.icon {
  position: relative;
  top: 4px;
}
</style>
